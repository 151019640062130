<template>
  <div ref="mainbox" class="mainbox">
    <!-- Intro -->
    <section class="section-main section-intro" id="main-intro" ref="mainIntro">
      <div class="section-main-inner">
        <div class="intro-text">
          <div class="txt-intro txt-intro01">
            <div class="mo_line slice-text" data-slice="By adding value">
              <span class="_char">B</span>
              <span class="_char">y</span>
              <span class="_char space">&nbsp;</span>
              <span class="_char">a</span>
              <span class="_char">d</span>
              <span class="_char">d</span>
              <span class="_char">i</span>
              <span class="_char">n</span>
              <span class="_char">g</span>
              <span class="_char space">&nbsp;</span>
              <span class="_char">v</span>
              <span class="_char">a</span>
              <span class="_char">l</span>
              <span class="_char">u</span>
              <span class="_char">e</span>
            </div>
            <div class="mo_line slice-text" data-slice="to the experience">
              <span class="_char">t</span>
              <span class="_char">o</span>
              <span class="_char space">&nbsp;</span>
              <span class="_char">t</span>
              <span class="_char">h</span>
              <span class="_char">e</span>
              <span class="_char space">&nbsp;</span>
              <span class="_char">e</span>
              <span class="_char">x</span>
              <span class="_char">p</span>
              <span class="_char">e</span>
              <span class="_char">r</span>
              <span class="_char">i</span>
              <span class="_char">e</span>
              <span class="_char">n</span>
              <span class="_char">c</span>
              <span class="_char">e</span>
            </div>
          </div>
          <div class="txt-intro txt-intro02">
            <div class="mo_line slice-text" data-slice="We make greatness">
              <span class="_char">W</span>
              <span class="_char">e</span>
              <span class="_char space">&nbsp;</span>
              <span class="_char">m</span>
              <span class="_char">a</span>
              <span class="_char">k</span>
              <span class="_char">e</span>
              <span class="_char space">&nbsp;</span>
              <span class="_char">g</span>
              <span class="_char">r</span>
              <span class="_char">e</span>
              <span class="_char">a</span>
              <span class="_char">t</span>
              <span class="_char">n</span>
              <span class="_char">e</span>
              <span class="_char">s</span>
              <span class="_char">s</span>
            </div>
          </div>
        </div>
        <div class="intro-bg"></div>
      </div>
    </section>

    <!-- section 01 -->
    <section class="section-main section-slogan">
      <div class="section-main-inner">
        <div class="slogan-text">
          <div class="txt-slogan txt-slogan01">
            <div class="mo_line slice-text" data-slice="By adding value">
              <span class="_char">B</span>
              <span class="_char">y</span>
              <span class="_char space">&nbsp;</span>
              <span class="_char">a</span>
              <span class="_char">d</span>
              <span class="_char">d</span>
              <span class="_char">i</span>
              <span class="_char">n</span>
              <span class="_char">g</span>
              <span class="_char space">&nbsp;</span>
              <span class="_char">v</span>
              <span class="_char">a</span>
              <span class="_char">l</span>
              <span class="_char">u</span>
              <span class="_char">e</span>
            </div>
            <div class="mo_line slice-text" data-slice="to the experience">
              <span class="_char">t</span>
              <span class="_char">o</span>
              <span class="_char space">&nbsp;</span>
              <span class="_char">t</span>
              <span class="_char">h</span>
              <span class="_char">e</span>
              <span class="_char space">&nbsp;</span>
              <span class="_char">e</span>
              <span class="_char">x</span>
              <span class="_char">p</span>
              <span class="_char">e</span>
              <span class="_char">r</span>
              <span class="_char">i</span>
              <span class="_char">e</span>
              <span class="_char">n</span>
              <span class="_char">c</span>
              <span class="_char">e</span>
            </div>
          </div>
          <div class="txt-slogan txt-slogan02">
            <div class="mo_line slice-text" data-slice="We make greatness">
              <span class="_char">W</span>
              <span class="_char">e</span>
              <span class="_char space">&nbsp;</span>
              <span class="_char">m</span>
              <span class="_char">a</span>
              <span class="_char">k</span>
              <span class="_char">e</span>
              <span class="_char space">&nbsp;</span>
              <span class="_char">g</span>
              <span class="_char">r</span>
              <span class="_char">e</span>
              <span class="_char">a</span>
              <span class="_char">t</span>
              <span class="_char">n</span>
              <span class="_char">e</span>
              <span class="_char">s</span>
              <span class="_char">s</span>
            </div>
          </div>
        </div>

        <div class="scroll-down">
          <i class="ico-arr"></i><span class="txt">Scroll to Explore</span>
        </div>
      </div>
    </section>

    <!-- section 02 Work -->
    <section class="section-main section-work" data-link="work">
      <div class="section-work-inner">
        <h2 class="sec-tit">GREAT PERFORMANCE</h2>
        <div class="work-wrap sec-cont">
          <section class="work-list">
            <ul>
              <li
                v-for="(item, index) in WorksListData"
                :key="item.id"
                :class="{active: index === 0}"
              >
                <router-link class="item item-work" :to="`/works/${item.id}`">
                  <div class="img-area">
                    <img
                      :src="imgUrl(item.moSmallImagePhysicalName)"
                      :alt="item.projectClientName"
                    />
                  </div>
                  <div class="cont-area">
                    <div class="itm-cont itm-tag" v-show="item.prize && item.prize !== ''">
                      {{ item.prize }}
                    </div>
                    <div class="itm-cont itm-tit">{{ item.projectServiceName }}</div>
                    <div class="itm-cont itm-date">{{ item.releaseDate }}</div>
                  </div>
                </router-link>
              </li>
            </ul>
          </section>
        </div>
      </div>
    </section>

    <!-- section03 Business -->
    <section class="section-main section-business">
      <div class="section-business-box">
        <div class="section-main-inner sec-cont">
          <div class="bg">
            <video id="video" autoplay playsinline muted loop preload="auto" type="video/mp4">
              <!-- autoplay loop -->
              <source src="@/assets/images/emotion_mobile.mp4" type="video/mp4" />
            </video>
          </div>
          <div class="cont business-cont">
            <section class="sec sec-text">
              <div class="text-wrap">
                <div class="txt-main">
                  <div class="_line" data-slice="We are digital experts">
                    We are digital experts
                  </div>
                  <div class="_line" data-slice="who will make">
                    who will make
                  </div>
                  <div class="_line" data-slice="your business greater.">
                    your business greater.
                  </div>
                </div>
                <div class="txt-sub">
                  <div>With e · motion, you can do anything</div>
                  <div>and become anyone.</div>
                </div>
              </div>
            </section>
            <section class="sec sec-list">
              <div class="list-wrap">
                <ul>
                  <li class="_active">UX / CX Consulting</li>
                  <li>AI Digital Solution</li>
                  <li>Data Analysis and Visualization</li>
                  <li>UX Research & Strategy</li>
                  <li>Experience Design</li>
                  <li>UI / GUI Design</li>
                  <li>Interaction Design</li>
                  <li>2D / 3D Motiongraphic</li>
                  <li>Brand Identity Design</li>
                  <li>Total Management</li>
                  <li>Creative idea&Development</li>
                </ul>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>

    <!-- section 04 Magazine -->
    <section class="section-main section-magazine" data-link="magazine">
      <div class="section-main-inner">
        <h2 class="sec-tit">RADICAL THINKING. <span class="color2">MAGAZINE</span></h2>
        <div class="magazine-wrap sec-cont">
          <ul class="item-list">
            <li v-for="(item, index) in magazineListData" :key="index" class="item">
              <router-link
                :to="`/magazine/${item.id}/?list=${totalLength - index}`"
                class="article"
              >
                <div class="img-area">
                  <img :src="imgUrl(item.moKeyPicturePhysicalName)" :alt="item.magazineTitle" />
                </div>
                <div class="cont-area">
                  <ul>
                    <li class="art-date">{{ item.reportingDate | dateFormat }}</li>
                    <li class="art-tit">{{ item.magazineTitle }}</li>
                    <li class="art-desc">
                      {{ item.magazineDesc }}
                    </li>
                  </ul>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <!-- <div class="key-visual">
      <div class="mask">
        <div class="btn-area">
          <button
            type="button"
            :class="{muted: isMuted}"
            @click="soundHandler"
            aria-label="음소거 버튼"
          >
            <lottie-muted v-if="!isMuted"></lottie-muted>
          </button>
          <div class="right">
            <a
              class="social"
              href="https://www.facebook.com/emotion.co.kr/"
              aria-label="facebook"
              target="_blank"
            ></a>
            <a href="https://www.behance.net/emotionglobal" aria-label="Behance" target="_blank">Behance</a>
            <router-link to="/request">프로젝트 문의</router-link>
          </div>
        </div>
        <div class="video-item">
          <video id="vid" autoplay playsinline muted loop>
            <source src="@/assets/images/emotion_mobile.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
    </div> -->
    <!-- <div class="contents">
      <div class="title-area mt0">
        <strong class="title">
          <span>
            이모션글로벌의<br />
            전문가들은 각기 다른 재능으로<br />
          </span>
          위대함을 만들어 갑니다.
        </strong>
        <router-link to="/about" class="more">View More</router-link>
      </div>
    </div> -->
    <!-- <div v-swiper:mySwiper="swiperOptions">
      <div class="swiper-wrapper swiper-area">
        <div class="swiper-slide swiper-item" v-for="(item, index) in swiperData" :key="index">
          <div class="about-item">
            <span class="thumb">
              <img :src="item.subImagePhysicalName" alt="이미지" />
            </span>
            <strong class="title">
              {{ item.title }}
            </strong>
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div> -->
    <!-- <div class="contents" id="test1">
      <div class="title-area type2">
        <strong class="sub-title"> Featured Works </strong>
        <router-link to="/works" class="more">View More</router-link>
      </div>
      <div class="work-list">
        <div v-for="(item, index) in WorksListData" :key="index" class="work-item">
          <router-link :to="`/works/${item.id}`">
            <span class="thumb">
              <img :src="imgUrl(item.moSmallImagePhysicalName)" :alt="item.projectClientName" />
            </span>
            <span class="title" :style="`color:${item.listFont}`">
              {{ item.projectServiceName }}
            </span>
          </router-link>
        </div>
      </div>

      <div class="title-area">
        <strong class="title">
          <span>이모션글로벌의 시선으로</span><br />
          세상을 이야기합니다.
        </strong>
        <router-link to="/magazine" class="more">View More</router-link>
      </div>
      <ul class="magazine-list">
        <li v-for="(item, index) in magazineListData" :key="index">
          <router-link :to="`/magazine/${item.id}/?list=${totalLength - index}`">
            <span class="num">
              {{ totalLength - index }}
            </span>
            <strong class="title">
              {{ item.magazineTitle }}
            </strong>

            <span class="date">
              {{ item.reportingDate | dateFormat }}
            </span>
            <span class="desc">
              {{ item.magazineDesc }}
            </span>
          </router-link>
        </li>
      </ul>
    </div> -->
  </div>
</template>
<script>
  import {TweenMax, Power0, Power1, Power3, Expo, Back, TimelineMax} from "gsap";
  import {apiMagazineList, apiWorksList} from "@/api";
  import {commonMethods} from "@/utils/common-methods";
  // import LottieMuted from "@/components/lottie/muted.vue";
  // import bus from "@/utils/bus";

  export default {
    name: "home",
    // components: {LottieMuted},
    data() {
      return {
        windowTop: window.top.scrollY,
        mainSloganTl: null,
        isMuted: true,
        scene0: null,
        scene: null,
        scene2: null,
        scene3: null,
        scene4: null,
        prevItemsST: null,
        itemCount: 0,
        isMoving: false,
        workTimeout: null,
        sectionName: "",
        swiperData: [
          {
            title: "UX / CX Consulting",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/00_UX-CX.png")
          },
          {
            title: "UX Research & Strategy",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/01_Research-Strategy.png")
          },
          {
            title: "Experience Design",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/02_Experience.png")
          },
          {
            title: "UI / GUI Design",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/03_UI-GUI.png")
          },

          {
            title: "Brand Identity Design",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/04_Brand-Identity.png")
          },

          {
            title: "Interaction Design",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/05_Interaction-Design.png")
          },
          {
            title: "2D/3D Motiongraphic",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/06_Motiongraphic.png")
          },
          {
            title: "Creative idea",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/07_Creative-Idea.png")
          },
          {
            title: "Creative Development",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/08_Creative-Development.png")
          },
          // {
          //   title: "Digital Transformation",
          //   link: "/",
          //   subImagePhysicalName: require("@/assets/images/about/09_Digtal-Transformation.png")
          // },
          // {
          //   title: "SNS Marketing",
          //   link: "/",
          //   subImagePhysicalName: require("@/assets/images/about/10_SNS-Marketing.png")
          // },
          {
            title: "Total Management",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/11_Total-Management.png")
          }
        ],
        totalLength: 0,
        magazineListData: [],
        WorksListData: [],
        swiperOptions: {
          autoplay: true,
          loop: true,
          centeredSlides: true,
          slidesPerView: "auto"
        }
      };
    },
    mixins: [commonMethods],
    created() {
      this.magazineList();
      this.workList();
    },
    activated() {
      this.init();
    },
    mounted() {
      this.init();
      if (this.scene0 !== null) {
        this.$scrollmagic.addScene(this.scene0);
      }
      if (this.scene !== null) {
        this.$scrollmagic.addScene(this.scene);
      }
      if (this.scene2 !== null) {
        this.$scrollmagic.addScene(this.scene2);
      }
      if (this.scene3 !== null) {
        this.$scrollmagic.addScene(this.scene3);
      }
      if (this.scene4 !== null) {
        this.$scrollmagic.addScene(this.scene4);
      }

      setTimeout(this.mainIntro, 150);

      // smooth scrollbar Event
      window.addEventListener("scroll", this.onScroll);
      // window.addEventListener("resize", () => {
      //   bus.$emit("winScrollTo", 0, 0);
      //   this.scene?.destroy(true);
      //   this.scene0?.destroy(true);
      //   this.scene2?.destroy(true);
      //   this.scene3?.destroy(true);
      //   this.scene4?.destroy(true);
      //   setTimeout(() => {
      //     this.mainSlogan();
      //   }, 500);
      // });
    },
    beforeDestroy() {
      window.clearTimeout(this.workTimeout);
      this.scene?.destroy(true);
      this.scene0?.destroy(true);
      this.scene2?.destroy(true);
      this.scene3?.destroy(true);
      this.scene4?.destroy(true);
      if (this.mainSloganTl !== null) {
        this.mainSloganTl = null;
      }
      window.removeEventListener("scroll", () => {
        this.introScroll();
        this.onScroll();
      });
      // window.removeEventListener("resize", () => {
      //   this.mainSlogan();
      // });
      const workLink = document.querySelector(".main-floating-link.link-work");
      const magazineLink = document.querySelector(".main-floating-link.link-magazine");
      setTimeout(() => {
        TweenMax.to(magazineLink, 0.2, {
          autoAlpha: 0,
          y: 100
        });

        TweenMax.to(workLink, 0.2, {
          autoAlpha: 0,
          y: 100
        });
      }, 200);
    },
    destroyed() {
      window.clearTimeout(this.workTimeout);
      this.scene?.destroy(true);
      this.scene0?.destroy(true);
      this.scene2?.destroy(true);
      this.scene3?.destroy(true);
      this.scene4?.destroy(true);
      window.removeEventListener("scroll", () => {
        this.introScroll();
        this.onScroll();
      });
      // window.removeEventListener("resize", () => {
      //   this.mainSlogan();
      // });
      const workLink = document.querySelector(".main-floating-link.link-work");
      const magazineLink = document.querySelector(".main-floating-link.link-magazine");
      setTimeout(() => {
        TweenMax.to(magazineLink, 0.2, {
          autoAlpha: 0,
          y: 100
        });

        TweenMax.to(workLink, 0.2, {
          autoAlpha: 0,
          y: 100
        });
      }, 200);
    },
    methods: {
      onScroll() {
        this.windowTop = window.top.scrollY;
        // console.log(window.top.scrollY);
        if (document.querySelector(".section-magazine") !== null) {
          const workLink = document.querySelector(".main-floating-link.link-work");
          const magazineLink = document.querySelector(".main-floating-link.link-magazine");
          const workList = document.querySelectorAll(".work-wrap .work-list li");
          const magazineList = document.querySelectorAll(".magazine-wrap .item-list li");

          if (window.top.scrollY < 50) {
            if (this.sectionName !== "home") {
              TweenMax.to(magazineLink, 0.2, {
                autoAlpha: 0,
                y: 100
              });

              TweenMax.to(workLink, 0.2, {
                autoAlpha: 0,
                y: 100
              });
            }
            this.sectionName = "home";
          } else if (
            window.top.scrollY <
            document.querySelector(".section-business").offsetTop - window.innerHeight
          ) {
            if (this.sectionName !== "works") {
              TweenMax.to(magazineLink, 0.2, {
                autoAlpha: 0,
                y: 100
              });
              TweenMax.to(workLink, 0.3, {
                autoAlpha: 1,
                y: 0,
                delay: 0.2,
                ease: Back.easeOut
              });
            }

            if (
              window.top.scrollY >
              document.querySelector(".section-work").offsetTop - window.innerHeight / 2.5
            ) {
              workList.forEach((item, index) => {
                TweenMax.to(item, 0.3, {
                  autoAlpha: 1,
                  y: 0,
                  delay: index * 0.1,
                  ease: Power1.easeOut
                });
              });
            } else {
              workList.forEach(item => {
                TweenMax.to(item, 0.2, {
                  autoAlpha: 0,
                  y: 100
                });
              });
            }
            this.sectionName = "works";
          } else if (
            window.top.scrollY <
            document.querySelector(".section-magazine").offsetTop - window.innerHeight
          ) {
            if (this.sectionName !== "business") {
              TweenMax.to(magazineLink, 0.2, {
                autoAlpha: 0,
                y: 100
              });
              TweenMax.to(workLink, 0.2, {
                autoAlpha: 0,
                y: 100
              });
            }
            this.sectionName = "business";
          } else if (
            window.top.scrollY >
              document.querySelector(".section-magazine").offsetTop - window.innerHeight &&
            window.top.scrollY <
              document.querySelector(".new-footer").offsetTop - window.innerHeight
          ) {
            if (this.sectionName !== "magazine") {
              TweenMax.to(workLink, 0.2, {
                autoAlpha: 0,
                y: 100
              });
              TweenMax.to(magazineLink, 0.3, {
                autoAlpha: 1,
                y: 0,
                delay: 0.2,
                ease: Back.easeOut
              });
            }

            if (
              window.top.scrollY >
              document.querySelector(".section-magazine").offsetTop - window.innerHeight / 2.5
            ) {
              magazineList.forEach((item, index) => {
                TweenMax.to(item, 0.3, {
                  autoAlpha: 1,
                  y: 0,
                  delay: index * 0.1,
                  ease: Power1.easeOut
                });
              });
            } else {
              magazineList.forEach(item => {
                TweenMax.to(item, 0.2, {
                  autoAlpha: 0,
                  y: 100
                });
              });
            }
            this.sectionName = "magazine";
          } else {
            if (this.sectionName !== "") {
              TweenMax.to(magazineLink, 0.2, {
                autoAlpha: 0,
                y: 100
              });

              TweenMax.to(workLink, 0.2, {
                autoAlpha: 0,
                y: 100
              });
            }
            this.sectionName = "";
          }
          // console.log(this.sectionName);
        }
      },
      init() {
        this.scene0?.destroy(true);
        this.scene?.destroy(true);
        this.scene2?.destroy(true);
        this.scene3?.destroy(true);
        this.scene4?.destroy(true);
        // const keyVisual = document.querySelector(".mainbox");
        // const tween = TweenMax.to(keyVisual.querySelector(".section-intro"), 0.1, {
        //   y: keyVisual.querySelector(".section-intro").clientHeight
        //   // ease: Power0.easeInOut
        // });
        // this.scene = this.$scrollmagic
        //   .scene({
        //     triggerElement: keyVisual,
        //     triggerHook: 0,
        //     duration: keyVisual.clientHeight * 2
        //   })
        //   .setTween([tween]);
        // this.$scrollmagic.addScene(this.scene);
        const workLink = document.querySelector(".main-floating-link.link-work");
        const magazineLink = document.querySelector(".main-floating-link.link-magazine");
        TweenMax.to(workLink, 0.2, {
          autoAlpha: 0,
          y: 100
        });
        TweenMax.to(magazineLink, 0.2, {
          autoAlpha: 0,
          y: 100
        });
      },
      sliceText(elem) {
        if (!elem) return;

        const text = elem.dataset.slice || "";
        elem.innerHTML = [...text]
          .map(char => `<span class="_char">${char === " " ? "&nbsp;" : char}</span>`)
          .join("");
      },
      mainIntro() {
        const introBg = document.querySelector(".section-intro .intro-bg");
        const secIntro = document.querySelector(".section-intro");
        const introTexts = secIntro.querySelectorAll(".txt-intro");
        const intro01 = introTexts[0];
        const intro02 = introTexts[1];
        const intro01Char = intro01.querySelectorAll("._char");
        const intro02Char = intro02.querySelectorAll("._char");
        const mainFooter = document.querySelector(".new-footer");
        const mainFloating = document.querySelector(".floating-menu");
        const mainIntro = this.$refs.mainIntro;
        const mainIntroTl = new TimelineMax({
          defaults: {ease: "none"}
        });

        // const tween0 = TweenMax.to(document.querySelector(".section-intro"), 0.1, {
        //   ease: Power0.easeInOut,
        //   // top: () => parseInt(document.querySelector(".scroll-content").style.transform.split(",")[1]) * -1,
        //   onUpdate: () => {
        //     TweenMax.to(document.querySelector(".section-intro"), 0.3, {
        //       ease: Power0.easeInOut,
        //       top: () => parseInt(document.querySelector(".scroll-content").style.transform.split(",")[1]) * -1
        //     });
        //     console.log( document.querySelector(".scroll-content").style.transform, parseInt(document.querySelector(".scroll-content").style.transform.split(",")[1]) * -1 );
        //   }
        // });

        // this.scene0 = this.$scrollmagic
        //   .scene({
        //     triggerElement: document.querySelector(".section-intro"),
        //     // offset: window.top.scrollY - (secWork.offsetTop - (window.innerHeight - secWorkCont.offsetHeight)),
        //     triggerHook: 0,
        //     duration: document.querySelector(".scroll-content").clientHeight
        //   })
        //   // .addIndicators()
        //   .setTween([tween0]);
        // this.$scrollmagic.addScene(this.scene0);

        let vueThis = this;
        const workPrevItem = document.querySelectorAll(".work-thumbnail li");
        TweenMax.set(workPrevItem, {autoAlpha: 0});

        mainIntroTl
          // intro slogan 01
          .set(mainFooter, {autoAlpha: 0, display: "none"})
          .set(mainFloating, {autoAlpha: 0})
          .set(intro01, {autoAlpha: 1})
          .staggerFrom(
            intro01Char,
            0.4,
            {
              autoAlpha: 0,
              y: 10,
              ease: Back
            },
            0.024
          )
          .staggerTo(
            intro01Char,
            0.4,
            {
              rotation: () => Math.floor(Math.random() * 41) - 20,
              y: -5,
              ease: Back
            },
            0.015
          )
          .staggerTo(
            intro01Char,
            0.3,
            {
              autoAlpha: 0,
              y: 0
              // ease: Back.easeOut
            },
            0.01
          )
          // intro slogan 02
          .to(intro02, 0, {autoAlpha: 1}, "-=.5")
          .staggerFrom(
            intro02Char,
            0.4,
            {
              autoAlpha: 0,
              y: 10,
              ease: Back
            },
            0.025
          )
          .staggerTo(
            intro02Char,
            0.4,
            {
              rotation: () => Math.floor(Math.random() * 41) - 20,
              y: -5,
              ease: Back
            },
            0.025
          )
          .staggerTo(
            intro02Char,
            0.3,
            {
              autoAlpha: 0,
              y: 0,
              scale: 1.2
            },
            0.025
          )
          .to(".mainbox", 0, {
            height: "auto",
            onComplete: () => {
              // 메인 슬로건 시작
              vueThis.mainSlogan();
              // work thumbnail 등장
              vueThis.workThumbnailShow();
              vueThis.$refs.mainbox.classList.add("active");
              window.setTimeout(vueThis.mainWork, 200);
            }
          })
          .to(mainIntro, 0.3, {autoAlpha: "0"})
          .fromTo(introBg, 1, {scale: 0}, {scale: 200, ease: Expo.inOut}, "<")
          .to(mainFooter, 0.5, {
            autoAlpha: 1,
            display: "block",
            onComplete: () => {
              mainIntro.style.display = "none";
            }
          })
          .to(mainFloating, 0.5, {autoAlpha: 1}, "<");
        // }
      },
      mainSlogan() {
        this.scene2?.destroy(true);
        this.scene3?.destroy(true);

        const secSlogan = document.querySelector(".section-slogan");
        const sloganTexts = secSlogan.querySelectorAll(".txt-slogan");
        const slogan01 = sloganTexts[0];
        const slogan02 = sloganTexts[1];
        const slogan01Char = slogan01.querySelectorAll("._char");
        const slogan02Char = slogan02.querySelectorAll("._char");
        if (this.mainSloganTl === null) {
          this.mainSloganTl = new TimelineMax({
            defaults: {ease: "none"},
            repeat: -1
          });
        } else {
          // console.log(this.mainSloganTl);
          this.mainSloganTl.invalidate();
          setTimeout(() => {
            this.mainSloganTl.restart();
          }, 1000);
        }
        // console.log(sloganTexts, slogan01, slogan01Char);

        this.mainSloganTl
          // slogan 01
          .to(slogan01, 0, {autoAlpha: 1})
          .staggerFrom(
            slogan01Char,
            0.4,
            {
              autoAlpha: 0,
              y: 10,
              ease: Back
            },
            0.024
          )
          .staggerTo(
            slogan01Char,
            0.4,
            {
              rotation: () => Math.floor(Math.random() * 41) - 20,
              y: -5,
              // yoyo: true,
              // repeat: 1,
              ease: Back
            },
            0.018
          )
          .staggerTo(
            slogan01Char,
            0.4,
            {
              rotation: 0,
              y: 0,
              ease: Back
            },
            0.02
          )
          .staggerTo(
            slogan01Char,
            0.3,
            {
              autoAlpha: 0,
              y: 0,
              scale: 1.2,
              ease: Back.easeOut
            },
            0.01
          )
          // slogan 02
          .to(slogan02, 0, {autoAlpha: 1}, "-=.5")
          .staggerFrom(
            slogan02Char,
            0.4,
            {
              autoAlpha: 0,
              y: 10,
              ease: Back
            },
            0.025
          )
          .staggerTo(
            slogan02Char,
            0.4,
            {
              rotation: () => Math.floor(Math.random() * 21) - 10,
              y: -5,
              ease: Back
            },
            0.025
          )
          .staggerTo(
            slogan02Char,
            0.4,
            {
              rotation: 0,
              y: 0,
              ease: Back
            },
            0.025
          )
          .staggerTo(
            slogan02Char,
            0.3,
            {
              autoAlpha: 0,
              y: 0,
              scale: 1.2
            },
            0.02
          );

        if (document.querySelector(".business-cont .list-wrap") !== null) {
          const busiTextMain = document.querySelector(".business-cont .text-wrap .txt-main");
          const busiTextSub = document.querySelector(".business-cont .text-wrap .txt-sub");
          const busiList = document.querySelector(".business-cont .sec-list");

          document.querySelector(".section-business .list-wrap").style.top = `${window.innerHeight /
            2}px`;
          // const secBuzIn = document.querySelector(".section-business .sec-cont");

          const tween3 = TweenMax.to(document.querySelector(".section-business-box"), 2, {
            ease: Power0.easeInOut,
            paddingTop: () => window.innerHeight
            // onUpdate: () => {
            //   console.log(this.windowTop, document.querySelector(".section-business").offsetTop);
            // }
          });
          const tween30 = new TimelineMax();
          tween30
            .from(busiList, 0.1, {autoAlpha: 0, y: 0, ease: Back})
            .from(busiTextMain, 0.1, {autoAlpha: 1, y: 0, ease: Back}, "<")
            .from(busiTextSub, 0.1, {autoAlpha: 1, y: 0, delay: 0.05, ease: Back}, "<")
            .to(busiTextMain, 0.4, {autoAlpha: 0, y: 50, ease: Back})
            .to(
              busiTextSub,
              0.4,
              {
                autoAlpha: 0,
                y: 50,
                ease: Back,
                delay: 0.05
              },
              "<"
            )
            .to(busiList, 0.1, {
              autoAlpha: 1,
              y: 0
            })
            .to(document.querySelector(".section-business .list-wrap"), 1.1, {
              ease: Power0.easeOut,
              top: () =>
                window.innerHeight -
                document.querySelector(".business-cont .list-wrap").offsetHeight * 1,
              onUpdate: () => {
                const busiListWrap = document.querySelector(".business-cont .list-wrap");
                const busiListItems = busiListWrap.querySelectorAll("li");
                let topNumber =
                  (parseInt(busiListWrap.style.top, 10) - window.innerHeight / 2) * -1.6;
                let currentNumber = parseInt(
                  busiListWrap.offsetHeight / (busiListItems.length + 1)
                );
                let callNumber = parseInt(topNumber / currentNumber);

                busiListItems.forEach(item => {
                  if (item.classList.contains("_active")) {
                    TweenMax.to(item, 0.2, {
                      opacity: 0.1,
                      ease: Power0.easeInOut,
                      onComplete: () => {
                        item.classList.remove("_active");
                      }
                    });
                  } else {
                    TweenMax.to(item, 0.1, {opacity: 0.1, ease: Power0.easeInOut});
                  }
                });
                if (callNumber > 0 && callNumber < busiListItems.length) {
                  busiListItems[callNumber].classList.add("_active");
                  TweenMax.to(busiListItems[callNumber], 0.2, {opacity: 1, ease: Power3.easeOut});
                } else if (callNumber <= 0) {
                  busiListItems[0].classList.add("_active");
                  TweenMax.to(busiListItems[0], 0.2, {opacity: 1, ease: Power3.easeOut});
                } else {
                  busiListItems[busiListItems.length - 1].classList.add("_active");
                  TweenMax.to(busiListItems[busiListItems.length - 1], 0.2, {
                    opacity: 1,
                    ease: Power3.easeOut
                  });
                }
              }
            });

          this.scene3 = this.$scrollmagic
            .scene({
              triggerElement: document.querySelector(".section-business"),
              offset: 0,
              triggerHook: 0,
              duration: document.querySelector(".section-business").offsetHeight
            })
            // .addIndicators()
            .setTween([tween3, tween30]);
          // .on("end", () => {
          //   console.log("business scroll end");
          // });
          this.$scrollmagic.addScene(this.scene3);
        }
      },
      workThumbnailShow() {
        const workPrevItem = document.querySelectorAll(".work-thumbnail .item");

        const tl = new TimelineMax();
        tl.from(workPrevItem, 0, {autoAlpha: 0, y: 300})
          .to(workPrevItem, 0.4, {autoAlpha: 0, y: 300})
          .staggerTo(
            workPrevItem,
            0.3,
            {
              autoAlpha: 1,
              y: 0
            },
            0.1
          );
        return tl;
      },
      mainWork() {
        console.log("main-work");
        // setTimeout(this.setWorkItemsSize, 100);
        // smooth scrollbar Event

        // Work List 진입 (Work prev.)
        // this.prevItemsCreateST();
      },
      mainBusiness() {
        console.log("mainBusiness");
      },
      mainMagazine() {
        this.scene4?.destroy(true);
        // console.log("mainMagazine");
        const secMagazine = document.querySelector(".section-magazine");
        const magList = secMagazine.querySelector(".item-list");
        const articles = magList.querySelectorAll(".article");

        const articleEnter = new TimelineMax();

        // 매거진 아이템 등장
        const t1 = articleEnter.staggerFromTo(
          articles,
          0.4,
          {
            autoAlpha: 0,
            yPercent: 15
          },
          {
            autoAlpha: 1,
            yPercent: 0,
            ease: Power1.easeInOut,
            onComplete: () => {
              articles.forEach(item => {
                item.classList.add("_active");
              });
            }
          },
          0.1
        );

        this.scene4 = this.$scrollmagic
          .scene({
            triggerElement: document.querySelector(".section-magazine"),
            triggerHook: 0.6,
            duration: 250
          })
          .setTween([t1]);
        this.$scrollmagic.addScene(this.scene4);
      },
      async magazineList() {
        try {
          const {
            data: {data: response}
          } = await apiMagazineList({
            page: 0,
            size: 5,
            Keyword: ""
          });
          this.magazineListData = response.content;
          this.totalLength = parseInt(response.totalElements);
          console.log(response.content);
        } catch (error) {
          console.error(error);
        }
      },
      async workList() {
        try {
          const {
            data: {data: response}
          } = await apiWorksList({
            page: 0,
            size: 6,
            Keyword: ""
          });
          this.WorksListData = this.WorksListData.concat(response.content);
          //console.log(response.content);
        } catch (error) {
          console.error(error);
        }
      }
    }
  };
</script>
<style lang="scss" scoped>
  html.main-html {
    background: #f9f9f9;
    overflow: hidden;
  }

  .mainbox {
    overflow: hidden;
    // position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    &.active {
      position: relative;
      height: auto;
    }
  }

  // 플로팅 영역 폰트
  .main-floating-section-link,
  .floating-menu {
    * {
      font-family: "Noto Sans KR", sans-serif;
    }
  }
  // 플로팅 섹션 링크
  .main-floating-section-link {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 10;
    // z-index: 999;

    .main-floating-link {
      position: absolute;
      opacity: 0;
      // visibility: hidden;

      .floating-btn {
        position: absolute;
        left: 50%;
        bottom: -75px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 12px;
        height: 52px;
        padding: 0 12px 0 6px;
        border-radius: 26px;
        background: rgba(238, 238, 238, 0.8);
        box-shadow: 0 5px 40px rgba(0, 0, 0, 0.2);
        transform: translateX(-50%);
        backdrop-filter: blur(3px);

        > span {
          display: flex;
          transition: 0.3s ease-in-out;
        }

        .btn-ico {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: #fff;
          color: #fff;
          &::after {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 24px;
            height: 24px;
            background: url("/assets/images/main/ico-e-red.svg") no-repeat center/contain;
            transform: translate(-50%, -50%) scale(1);
            transition: 0.3s;
          }

          &.ico-work::after {
            background-image: url(../assets/images/main/ico-work.svg);
          }

          &.ico-magazine::after {
            background-image: url(../assets/images/main/ico-magazine.svg);
          }
        }
        .btn-txt {
          color: #000;
          font-size: 12px;
          font-weight: 500;
          white-space: nowrap;
        }
        .btn-arr {
          position: relative;
          width: 18px;
          height: 18px;
          transform: translate3d(0, 0, 0);
          svg {
            width: 100%;
            height: 100%;
          }
        }

        // 버튼 hover
        // &:hover {
        // 	.btn-ico {
        // 		&::after {
        // 			// transform: translate(-50%, -50%);
        // 			animation: yoyoScale 0.3s alternate;
        // 			animation-iteration-count: 4;
        // 		}
        // 	}
        // 	.btn-txt {
        // 		color: #ee2c3c;
        // 	}
        // 	.btn-arr {
        // 		transform: rotate(45deg);

        // 		svg.arr-svg path {
        // 			stroke: #ee2c3c;
        // 		}
        // 	}
        // }
      }

      &.active {
        // border: 1px solid red;
        // opacity: 1;
        // visibility: visible;
      }
    }
  }

  @keyframes yoyoUp {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(0, -3px, 0);
    }
  }

  @keyframes yoyoDown {
    0% {
      transform: translate3d(0, 5px, 0) rotate(180deg);
    }
    100% {
      transform: translate3d(0, -5px, 0) rotate(180deg);
    }
  }

  @keyframes yoyoScale {
    0% {
      transform: translate3d(-50%, -50%, 0) scale(1);
    }
    100% {
      transform: translate3d(-50%, -50%, 0) scale(1.2);
    }
  }

  /////////////////////////////////////////////////////////////////////////////////
  ///  main section
  /////////////////////////////////////////////////////////////////////////////////
  #container {
  }

  // Section
  .section-main {
    * {
      font-family: "Montserrat", sans-serif;
    }
    position: relative;

    .section-main-inner {
      position: relative;
    }

    .sec-tit {
      margin-bottom: 30px;
      color: #000;
      font-size: 18px;
      font-family: "Montserrat", sans-serif;
      font-weight: 600;

      .color2 {
        color: #ee2c3c;
      }
    }
  }

  // Section Intro
  .section-intro {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1002;

    .section-main-inner {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    .intro-bg {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      transform: translate(-50%, 0);
      background-color: #fff;
      z-index: 2;
    }

    .intro-text {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      z-index: 1;
      &::before {
        content: "";
        display: block;
        position: absolute;
        inset: 0;
        background-color: #ee2c3c;
      }

      .txt-intro {
        position: absolute;
        top: 45%;
        left: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 0.2em;
        transform: translate(-50%, -50%);
        color: #fff;
        font-family: "Montserrat", sans-serif;
        font-size: clamp(30px, 5vw, 34px);
        font-size: 34px;
        font-weight: 600;
        letter-spacing: -0.05em;
        white-space: nowrap;
        opacity: 0; //@@ 임시
        .mo_line {
          position: relative;
          display: flex;
          ._char {
            &.space {
              min-width: 8px;
            }
            // display: block;
          }
        }
      }
    }
  }

  // Section Slogan
  .section-slogan {
    .section-main-inner {
      position: relative;
      width: 100vw;
      height: 100vh;
    }

    .txt-slogan {
      position: absolute;
      top: 45%;
      left: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 0.2em;
      transform: translate(-50%, -50%);
      color: #ee2c3c;
      font-family: "Montserrat", sans-serif;
      font-size: clamp(30px, 5vw, 34px);
      font-size: 34px;
      font-weight: 600;
      letter-spacing: -0.05em;
      white-space: nowrap;
      opacity: 0;
      .mo_line {
        position: relative;
        display: flex;
        ._char {
          &.space {
            min-width: 8px;
          }
        }
      }
    }

    .scroll-down {
      position: absolute;
      left: 20px;
      bottom: calc(env(safe-area-inset-bottom) + 40px);
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 8px;

      .ico-arr {
        position: relative;
        display: block;
        width: 18px;
        height: 18px;
        background: url(../assets/images/common/ico-arrow-up.svg) no-repeat center/contain;
        transition: 0.2s ease-in;
        animation: yoyoDown 0.3s infinite alternate;
      }
      .txt {
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
  // Work List Intro
  .work-prev {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 200vh;
    pointer-events: none;
    z-index: 3;

    .work-prev-inner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      .work-thumbnail {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;

        ul {
          display: flex;
          justify-content: center;

          li {
            position: relative;

            .item {
              position: relative;
              width: 100%;
              height: 100%;
              border-radius: 10px;
              overflow: hidden;

              img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }

          li:nth-child(1) {
            .item img {
              object-position: right top;
            }
          }

          li:nth-child(2) {
            margin: 0 20px;
          }

          li:nth-child(3) {
            .item img {
              object-position: left top;
            }
          }
        }
      }
    }
  }

  // Section Work
  .section-work {
    position: relative;
    width: 100vw;
    // height: 100vh;
    // .work-wrap {
    // }
    &._active {
      .work-wrap {
        opacity: 1;
      }
      // .section-main-inner {
      //   // background-color: pink;
      // }
    }
    .section-work-inner {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;
      padding: 60px 20px;
      box-sizing: border-box;
    }

    .work-wrap {
      .work-list {
        position: relative;
        overflow: hidden;
        border-radius: 10px;

        ul {
          width: 100%;
          position: relative;
          display: flex;
          flex-direction: column;
          row-gap: 20px;
          // li {
          //   // flex-shrink: 0;
          // }
        }

        .item-work {
          position: relative;
          display: block;
          border-radius: 6px;
          overflow: hidden;

          .img-area {
            position: relative;
            overflow: hidden;
            border-radius: 6px;
            z-index: 1;

            img {
              width: 100%;
              display: block;
              // max-height: 60vh;
              aspect-ratio: 3 / 2;
              transition: 0.4s ease-in-out;
            }
          }

          .cont-area {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;
            height: 100%;
            transition: 0.4s;
            // opacity: 0;
            z-index: 2;

            .itm-cont {
              margin: 16px;
              color: #fff;
            }

            .itm-tag {
              position: absolute;
              top: 0;
              left: 0;
              display: inline-flex;
              padding: 6px 10px;
              border: 1px solid #ddd;
              border-radius: 17px;
              background-color: #fff;
              color: #000;
              font-size: 10px;
              font-weight: 500;
              box-sizing: border-box;
            }
            .itm-tit {
              font-family: "Noto Sans KR", sans-serif;
              font-size: 16px;
              font-weight: 500;
            }
            .itm-date {
              margin: 16px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  // Section Business
  .section-business {
    .section-main-inner {
      position: relative;
      width: 100vw;
      height: 100vh;
    }

    .section-business-box {
      position: relative;
    }

    .bg {
      position: absolute;
      inset: 0;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
      }
      #video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .business-cont {
      position: relative;
      width: 100vw;
      height: 100vh;
      color: #fff;
      font-family: "Montserrat", sans-serif;

      * {
        position: relative;
        font-family: "Montserrat", sans-serif;
        line-height: 1.2;
      }

      .sec {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        // opacity: 0;
      }
      .text-wrap {
        .txt-main {
          position: relative;
          font-size: clamp(28px, 4.6vw, 32px);
          font-weight: 500;
          text-align: center;
          ._line {
            position: relative;
          }
        }

        .txt-sub {
          margin-top: 30px;
          color: #cccccc;
          font-size: clamp(16px, 1.9vw, 22px);
          font-weight: 400;
          text-align: center;
        }
      }

      // 비지니스 아이템 리스트
      .sec.sec-list {
        position: relative;
        width: 100vw;
        height: 100vh;
        flex-shrink: 0;
        display: flex;
        align-items: flex-start;
        overflow: hidden;
        opacity: 0;
      }
      .list-wrap {
        position: relative;

        ul {
          position: relative;
          display: flex;
          // flex-wrap: wrap;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          row-gap: 20px;
          padding: 0 80px;
          padding-bottom: 50vh;
          font-size: clamp(40px, 4.6vw, 82px);
          font-size: 28px;
          line-height: 1;
          li {
            position: relative;
            display: flex;
            justify-content: center;
            padding: 0 0.3em;
            color: #fff;
            font-weight: 500;
            line-height: 1;
            text-align: center;
            opacity: 0.1;
            // transition: opacity 0.2s ease-in;
            // text-align: center;
            // &._active {
            //   opacity: 1;
            // }
          }
        }
      }
    }
  }

  // Section Magazine
  .section-magazine {
    padding: 60px 20px 80px;

    .section-main-inner {
      max-width: 100vw;
    }

    .magazine-wrap {
      position: relative;
      width: 100%;
      .item-list {
        display: flex;
        flex-direction: column;
        gap: 20px;
        // .item {
        // }
      }
    }

    .article {
      position: relative;
      display: block;
      width: calc(100vw - 40px);
      height: calc(100vw - 40px);
      max-width: 100%;
      max-height: 100%;
      overflow: hidden;
      border-radius: 6px;
      color: #fff;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #ee2c3c;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        z-index: 2;
      }

      .img-area {
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
        border-radius: 6px;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      .cont-area {
        position: relative;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 1;

        > ul {
          max-width: 100%;
          padding: 30px 20px;
          li {
            position: relative;
            // opacity: 0;
            white-space: normal;
            word-break: break-all;
            line-height: 1.2;
            transition: all 0.4s ease-in;

            &.art-date {
              font-size: 12px;
            }
            &.art-tit {
              height: 50px;
              margin-top: 8px;
              font-family: "Noto Sans KR", sans-serif;
              font-size: 18px;
              line-height: 1.4;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
              // -webkit-box-orient: vertical;
            }
            &.art-desc {
              height: 28px;
              margin-top: 14px;
              font-family: "Noto Sans KR", sans-serif;
              font-size: 10px;
              font-weight: 400;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
            }
          }
        }
      }

      // _active
      &._active {
        .cont-area {
          ul {
            li {
              opacity: 1;
              transition: all 0.4s ease-in;
              transform: translate(0, 0);
            }
            li:nth-child(1) {
              transition-delay: 0.2s;
            }
            li:nth-child(2) {
              transition-delay: 0.3s;
            }
            li:nth-child(3) {
              transition-delay: 0.4s;
            }
          }
        }
      } //_active
    }
  }

  @keyframes textEnter {
    0% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes marquee {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  @keyframes marquee2 {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }
  // 메인의 플로팅 메뉴 #container 안으로 넣을 경우 보완 필요
  .fixed-wrap {
    position: fixed;
    inset: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    z-index: 9;

    .main-floating-section-link {
      position: absolute;
      bottom: 100px;
      pointer-events: initial;
    }
  }
</style>
